const news = [
  {
    year: '2024',
    month: '10.19',
    date: '2024年10月19日',
    updator: 'Zhijie Zhang',
    title: 'Congrats: Prof. Zhang was awarded the "2024 Scientific Explorer Prize" for his work in the intelligent prediction of RNA three-dimensional structures and small molecule intervention!',
    img: require('../assets/news/newsAvatar/2024SEA_avatar.png'),
    content: {
      text: 'Congrats: Prof. Zhang was awarded the "2024 Scientific Explorer Prize" for his work in the intelligent prediction of RNA three-dimensional structures and small molecule intervention!',
      img: [require('../assets/news/newsDetails/2024SEA.png')]
    },
    others: [
      {
        title: 'Event',
        content: 'Prof. Zhang was awarded the "2024 Scientific Explorer Prize" for his work in the intelligent prediction of RNA three-dimensional structures and small molecule intervention!'
      },
      {
        title: 'Time',
        content: '19 Oct 2024'
      }
    ]
  },
  {
    year: '2024',
    month: '5.31',
    date: '2024年5月31日',
    updator: 'Zilin Cai',
    title: 'Publication: Our paper on tissue module discovery in single-cell-resolution spatial transcriptomics data was accepted by Cell Systems!',
    img: require('../assets/news/newsAvatar/CellSystems.jpeg'),
    content: {
      text: 'Publication: Our paper on tissue module discovery in single-cell-resolution spatial transcriptomics data was accepted by Cell Systems!',
      img: [require('../assets/news/newsDetails/CellSystems_Liyuzhe_2024.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'Tissue module discovery in single-cell-resolution spatial transcriptomics data via cell-cell interaction-aware cell embedding'
      },
      {
        title: 'Authors',
        content: 'Y. Li, J. Zhang, et al.'
      },
      {
        title: 'Time',
        content: '31 May 2024'
      },
      {
        title: 'Link',
        url: 'https://doi.org/10.1016/j.cels.2024.05.001',
        content: 'Cell Systems'
      }
    ]
  },
  {
    year: '2024',
    month: '05.23',
    title: 'Congrats: Li Yuzhe passed the PhD thesis defense',
    date: '2024年05月23日',
    updator: 'Zilin Cai',
    content: {
      text: 'Li Yuzhe passed the PhD thesis defense',
      img: [require('../assets/news/newsDetails/20240523_liyuzhe.jpeg')]
    },
    img: require('../assets/news/newsAvatar/20240523_liyuzhe.jpg'),
    others: [
      {
        title: 'Event',
        content: 'Li Yuzhe passed the PhD thesis defense'
      },
      {
        title: 'Venue',
        content: 'Block B, Biomedical Building, THU'
      }
    ]
  },
  {
    year: '2024',
    month: '05.23',
    title: 'Congrats: Tian Kang passed the PhD thesis defense',
    date: '2024年05月23日',
    updator: 'Zilin Cai',
    content: {
      text: 'Tian Kang passed the PhD thesis defense',
      img: [require('../assets/news/newsDetails/20240523_tiankang.jpeg')]
    },
    img: require('../assets/news/newsAvatar/20240523_tiankang.jpg'),
    others: [
      {
        title: 'Event',
        content: 'Tian Kang passed the PhD thesis defense'
      },
      {
        title: 'Venue',
        content: 'Block B, Biomedical Building, THU'
      }
    ]
  },
  {
    year: '2024',
    month: '3.26',
    date: '2024年3月26日',
    updator: 'Zilin Cai',
    title: 'Publication: Our paper on mapping of the influenza A virus genome RNA structure and interactions was accepted by Cell Reports!',
    img: require('../assets/news/newsAvatar/CellReports.png'),
    content: {
      text: 'Publication: Our paper on mapping of the influenza A virus genome RNA structure and interactions was accepted by Cell Reports!',
      img: [require('../assets/news/newsDetails/CellReports_YangRui_2024.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'Mapping of the influenza A virus genome RNA structure and interactions reveals essential elements of viral replication'
      },
      {
        title: 'Authors',
        content: 'R. Yang, M. Pan, J. Guo, Y. Huang, et al.'
      },
      {
        title: 'Time',
        content: '26 Mar 2024'
      },
      {
        title: 'Link',
        url: 'https://doi.org/10.1016/j.celrep.2024.113833',
        content: 'Cell Reports'
      }
    ]
  },
  {
    year: '2024',
    month: '1.9',
    date: '2024年1月9日',
    updator: 'Zilin Cai',
    title: 'Publication: Our paper on identification of functionally conserved lncRNA was accepted by Nature Genetics!',
    img: require('../assets/news/newsAvatar/NG.jpg'),
    content: {
      text: 'Publication: Our paper on identification of functionally conserved lncRNA was accepted by Nature Genetics!',
      img: [require('../assets/news/newsDetails/NG_Wenze_2024.jpg')]
    },
    others: [
      {
        title: 'Title',
        content: 'Computational prediction and experimental validation identify functionally conserved lncRNAs from zebrafish to human'
      },
      {
        title: 'Authors',
        content: 'W. Huang, T. Xiong, Y. Zhao, et al.'
      },
      {
        title: 'Time',
        content: '9 Jan 2024'
      },
      {
        title: 'Link',
        url: 'https://www.nature.com/articles/s41588-023-01620-7',
        content: 'Nature Genetics'
      }
    ]
  },
  {
    year: '2023',
    month: '12.21',
    title: 'Congrats: Yang Rui passed the PhD thesis defense',
    date: '2023年12月21日',
    updator: 'Zilin Cai',
    content: {
      text: 'Yang Rui passed the PhD thesis defense',
      img: [require('../assets/news/newsDetails/20231221.jpeg')]
    },
    img: require('../assets/news/newsAvatar/20231221.jpg'),
    others: [
      {
        title: 'Event',
        content: 'Yang Rui passed the PhD thesis defense'
      },
      {
        title: 'Venue',
        content: 'Room 143, Bioscience Building, THU'
      }
    ]
  },
  {
    year: '2022',
    month: '11.17',
    date: '2022年11月21日',
    updator: 'Yiran Xu',
    title: 'Congrats: Our paper on predicting dynamic cellular protein–RNA interactions won the annual outstanding paper award of Cell Research!',
    img: require('../assets/news/newsAvatar/CellResearch.png'),
    content: {
      text: 'Our paper on predicting dynamic cellular protein–RNA interactions won the annual outstanding paper award of Cell Research!',
      img: [require('../assets/news/newsDetails/20210223_CellResearch.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures'
      },
      {
        title: 'Authors',
        content: 'Lei Sun, Kui Xu, Wenze Huang, Yucheng T. Yang et al.'
      },
      {
        title: 'Link',
        url: 'https://doi.org/10.1038/s41422-021-00476-y',
        content: 'Cell Research'
      }
    ]
  },
  {
      year: '2022',
      month: '8.17',
      title: 'Congrats: Prof. Zhang won the CSBMB-Promega Award for Biochemistry!',
      date: '2022年11月21日',
      updator: 'Yiran Xu',
      content: {
        text: 'Prof. Zhang won the CSBMB-Promega Award for Biochemistry!',
        img: [require('../assets/news/newsDetails/202208-prize.jpg')]
      },
      img: require('../assets/news/newsAvatar/202208.png'),
      others: [
        {
          title: 'Event',
          content: 'Prof. Zhang won the CSBMB-Promega Award for Biochemistry!'
        },
        {
        title: 'Time',
        content: '17 Aug 2022'
        }
      ]
    },
  {
    year: '2021',
    month: '11.17',
    date: '2021年11月17日',
    updator: 'Yuhan Fei',
    title: 'Publication: Our paper on missing RNA structure profiles imputation was accepted by Nature Machine Intelligence!',
    img: require('../assets/news/newsAvatar/NMI.jpg'),
    content: {
      text: 'Publication: Our paper on missing RNA structure profiles imputation was accepted by Nature Machine Intelligence!',
      img: [require('../assets/news/newsDetails/NMI_gongjing_2021.jpg')]
    },
    others: [
      {
        title: 'Title',
        content: 'A deep learning method for recovering missing signals in transcriptome-wide RNA structure profiles from probing experiments'
      },
      {
        title: 'Authors',
        content: 'J. Gong, K. Xu, et al.'
      },
      {
        title: 'Time',
        content: '17 Nov 2021'
      },
      {
        title: 'Link',
        url: 'https://www.nature.com/articles/s42256-021-00412-0',
        content: 'Nature Machine Intelligence'
      }
    ]
  },



  {
    year: '2021',
    month: '11.4',
    date: '2021年11月4日',
    updator: 'Yuhan Fei',
    title: 'Publication: Our paper on viral RNA–host protein interactomes was accepted by Cell Research!',
    img: require('../assets/news/newsAvatar/CellResearch.png'),
    content: {
      text: 'Publication: Our paper on viral RNA–host protein interactomes was accepted by Cell Research!',
      img: [require('../assets/news/newsDetails/cellres_shaojun_2021.jpg')]
    },
    others: [
      {
        title: 'Title',
        content: 'Comparison of viral RNA–host protein interactomes across pathogenic RNA viruses informs rapid antiviral drug discovery for SARS-CoV-2'
      },
      {
        title: 'Authors',
        content: 'S. Zhang, W. Huang, et al.'
      },
      {
        title: 'Time',
        content: '4 Nov 2021'
      },
      {
        title: 'Link',
        url: 'https://www.nature.com/articles/s41422-021-00581-y',
        content: 'Cell Research'
      }
    ]
  },





  {
    year: '2021',
    month: '11.1',
    date: '2021年11月1日',
    updator: 'Yuhan Fei',
    title: 'Publication: Our paper on chromatin-remodeling enzyme Smarca5 was accepted by eLife!',
    img: require('../assets/news/newsAvatar/elife.png'),
    content: {
      text: 'Publication: Our paper on chromatin-remodeling enzyme Smarca5 was accepted by eLife!',
      img: [require('../assets/news/newsDetails/elife_yuzhe_2021.jpg')]
    },
    others: [
      {
        title: 'Title',
        content: 'The chromatin-remodeling enzyme Smarca5 regulates erythrocyte aggregation via Keap1-Nrf2 signaling'
      },
      {
        title: 'Authors',
        content: 'Y. Ding, Y. Li, et al.'
      },
      {
        title: 'Time',
        content: '1 Nov 2021'
      },
      {
        title: 'Link',
        url: 'https://elifesciences.org/articles/72557',
        content: 'eLife'
      }
    ]
  },




  {
      year: '2021',
      month: '9.12',
      title: 'Congrats: Huang Wenze passed the PhD thesis defense',
      date: '2021年9月12日',
      updator: 'Yuhan Fei',
      content: {
        text: 'Huang Wenze passed the PhD thesis defense',
        img: [require('../assets/news/newsDetails/202109-group.jpg')]
      },
      img: require('../assets/news/newsAvatar/202109.png'),
      others: [
        {
          title: 'Event',
          content: 'Huang Wenze passed the PhD thesis defense'
        },
        {
          title: 'Venue',
          content: 'Room 325, Bioscience Building, THU'
        }
      ]
    },


  {
    year: '2021',
    month: '6.30',
    date: '2021年6月30日',
    updator: 'Yuhan Fei',
    title: 'Publication: Our paper on RNA structure probing review was accepted by Nature Chemical Biology!',
    img: require('../assets/news/newsAvatar/ncb.png'),
    content: {
      text: 'Publication: Our paper on RNA structure probing review was accepted by Nature Communications!',
      img: [require('../assets/news/newsDetails/NCB_xiwen_2021.jpg')]
    },
    others: [
      {
        title: 'Title',
        content: 'RNA structure probing uncovers RNA structure-dependent biological functions'
      },
      {
        title: 'Authors',
        content: 'X. Wang, C. Liu, et al.'
      },
      {
        title: 'Time',
        content: '30 June 2021'
      },
      {
        title: 'Link',
        url: 'https://www.nature.com/articles/s41589-021-00805-7',
        content: 'Nature Chemical Biology'
      }
    ]
  },

  {
    year: '2021',
    month: '6.7',
    date: '2021年6月7日',
    updator: 'Yuhan Fei',
    title: 'Publication: Our paper on icSHAPE-MaP of Dicer targeted RNAs was accepted by Nature Communications!',
    img: require('../assets/news/newsAvatar/nc.png'),
    content: {
      text: 'Publication: Our paper on icSHAPE-MaP of Dicer targeted RNAs was accepted by Nature Communications!',
      img: [require('../assets/news/newsDetails/NC_jinsong_2021.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'RNA structure probing reveals the structural basis of Dicer binding and cleavage'
      },
      {
        title: 'Authors',
        content: 'Q. Luo, J. Zhang, P. Li, et al.'
      },
      {
        title: 'Time',
        content: '07 June 2021'
      },
      {
        title: 'Link',
        url: 'https://www.nature.com/articles/s41467-021-23607-w',
        content: 'Nature Communications'
      }
    ]
  },
  {
      year: '2021',
      month: '5.23',
      title: 'Congrats: Li Pan, Xu Kui and Zhang Jinsong passed the PhD thesis defense',
      date: '2021年5月23日',
      updator: 'Yuhan Fei',
      content: {
        text: 'Li Pan, Xu Kui and Zhang Jinsong passed the PhD thesis defense',
        img: [require('../assets/news/newsDetails/202105-group.jpg')]
      },
      img: require('../assets/news/newsAvatar/202105.png'),
      others: [
        {
          title: 'Event',
          content: 'Li Pan, Xu Kui and Zhang Jinsong passed the PhD thesis defense'
        },
        {
          title: 'Venue',
          content: 'Room E109, Biomedical Building, THU'
        }
      ]
    },
  {
    year: '2021',
    month: '5.17',
    date: '2021年5月17日',
    updator: 'Pan Li',
    title: 'Seminar: We invited Dr. Marco MARCIA from EMBL Grenoble to talk about how RNA 3D structure determines MEG3 function',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/2021_MarcoMarcia.png')]
    },
    img: require('../assets/news/newsAvatar/MarcoMARCIA_400x400.jpg'),
    others: [
      {
        title: 'Title',
        content: 'The multiple dimensions of lncRNAs - How 3D structure determines MEG3 function'
      },
      {
        title: 'Speaker',
        content: 'Marco MARCIA, PhD'
      },
      {
        title: 'Time',
        content: '3:30-4:30 pm, May 17th (Monday), 2021'
      },
      {
        title: 'Zoom ID',
        content: '982 4074 1662'
      }
    ]
  },{
    year: '2021',
    month: '5.12',
    date: '2021年5月12日',
    updator: 'Pan Li',
    title: 'Lei Sun won Outstanding Postdoctoral Fellow of Tsinghua University!',
    img: require('../assets/news/newsAvatar/sunlei_2.png'),
    content: {
      text: 'Lei Sun won Outstanding Postdoctoral Fellow of Tsinghua University!',
      img: [require('../assets/news/newsDetails/Sunlei_Outstanding_Postdoctoral_Fellow.png')]
    },
    others: [
      {
        title: 'Direction',
        content: 'RNA secondary structure'
      },
      {
        title: 'Link',
        url: 'https://mp.weixin.qq.com/s/cMvf-IS9icSqIgryOQ1wgw',
        content: 'Tsinghua Postdoct'
      }
    ]
  },{
    year: '2021',
    month: '4.25',
    date: '2021年4月25日',
    updator: 'Pan Li',
    title: 'Lei Sun won the special postdoctoral fund!',
    img: require('../assets/news/newsAvatar/sunlei_2.png'),
    content: {
      text: 'Lei Sun won the special postdoctoral fund!',
      img: [require('../assets/news/newsDetails/sunlei_special_postdoctoral_fund.png')]
    },
    others: [
      {
        title: 'Direction',
        content: 'RNA secondary structure'
      },
      {
        title: 'Link',
        url: 'http://www.cls.edu.cn/Academicactivities/notices/index5284.shtml',
        content: 'CLS News'
      }
    ]
  },{
    year: '2021',
    month: '2.23',
    date: '2021年2月23日',
    updator: 'Pan Li',
    title: 'Publication: Our paper on Predicting dynamic cellular protein–RNA interactions was accepted by Cell Research!',
    img: require('../assets/news/newsAvatar/CellResearch.png'),
    content: {
      text: 'Publication: Our paper on Predicting dynamic cellular protein–RNA interactions was accepted by Cell Research!',
      img: [require('../assets/news/newsDetails/20210223_CellResearch.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures'
      },
      {
        title: 'Authors',
        content: 'Lei Sun, Kui Xu, Wenze Huang, Yucheng T. Yang et al.'
      },
      {
        title: 'Time',
        content: '23 Feb 2020'
      },
      {
        title: 'Link',
        url: 'https://doi.org/10.1038/s41422-021-00476-y',
        content: 'Cell Research'
      }
    ]
  },{
    year: '2021',
    month: '2.9',
    date: '2021年2月9日',
    updator: 'Pan Li',
    title: 'Publication: Our paper on structural characterization of the SARS-CoV-2 RNA genome was accepted by Cell!',
    img: require('../assets/news/newsAvatar/Cell.png'),
    content: {
      text: 'Publication: Our paper on structural characterization of the SARS-CoV-2 RNA genome was accepted by Cell!',
      img: [require('../assets/news/newsDetails/20210209_Cell.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'In vivo structural characterization of the SARS-CoV-2 RNA genome identifies host proteins vulnerable to repurposed drugs'
      },
      {
        title: 'Authors',
        content: 'Lei Sun,Pan Li,Xiaohui Ju,Jian Rao,Wenze Huang et al.'
      },
      {
        title: 'Time',
        content: '9 Feb 2021'
      },
      {
        title: 'Link',
        url: 'https://doi.org/10.1016/j.cell.2021.02.008',
        content: 'Cell'
      }
    ]
  },{
    year: '2021',
    month: '1.10',
    date: '2021年1月10日',
    updator: 'Pan Li',
    title: 'Lei Xiong won the Outstanding Graduates of Beijing!',
    img: require('../assets/news/newsAvatar/xionglei.png'),
    content: {
      text: 'Lei Xiong won the Outstanding Graduates of Beijing!',
      img: [require('../assets/news/newsDetails/Xionglei_Beijing.png')]
    },
    others: [
      {
        title: 'Direction',
        content: 'Single Cell'
      }
    ]
  },{
    year: '2020',
    month: '9.14',
    title: 'Congrats: Gong Jing, Piao Meiling and Xiong Lei passed the PhD thesis defense',
    date: '2020年9月14日',
    updator: 'Pan Li',
    content: {
      text: 'Xiong Lei,Piao Meiling and Gong jing passed the PhD thesis defense',
      img: [require('../assets/news/newsDetails/202009-group.jpg')]
    },
    img: require('../assets/news/newsAvatar/202009.png'),
    others: [
      {
        title: 'Event',
        content: 'Xiong Lei,Piao Meiling and Gong jing passed the PhD thesis defense'
      },
      {
        title: 'Venue',
        content: 'Room E109, Biomedical Building, THU'
      }
    ]
  },{
    year: '2020',
    month: '5.18',
    date: '2020年5月18日',
    updator: 'Pan Li',
    title: 'Publication: Our paper on RNA structural dynamics regulation of early embryogenesis was accepted by Genome Biology!',
    img: require('../assets/news/newsAvatar/GenomeBiology.png'),
    content: {
      text: 'Publication: Our paper on RNA structural dynamics regulation of early embryogenesis was accepted by Genome Biology!',
      img: [require('../assets/news/newsDetails/GenomeBiology-Jingsong-2020.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'RNA structural dynamics regulate early embryogenesis through controlling transcriptome fate and function'
      },
      {
        title: 'Authors',
        content: 'Boyang Shi, Jinsong Zhang, Jian Heng, Jing Gong et al.'
      },
      {
        title: 'Time',
        content: '18 May 2020'
      },
      {
        title: 'Link',
        url: 'https://doi.org/10.1186/s13059-020-02022-2',
        content: 'Genome Biology'
      }
    ]
  },{
    year: '2020',
    month: '2.17',
    date: '2020年2月17日',
    updator: 'Pan Li',
    title: 'Congrats: SCALE method is selected as "Top Ten Advances in Bioinformatics in China" in 2019',
    img: require('../assets/news/newsAvatar/GPB_top10.png'),
    content: {
      text: 'Congrats: SCALE method is selected as "Top Ten Advances in Bioinformatics in China" in 2019',
      img: [require('../assets/news/newsDetails/GPB_Top10_2019.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'SCALE method for single-cell ATAC-seq analysis via latent feature extraction'
      },
      {
        title: 'Authors',
        content: 'L. Xiong, K. Xu, K. Tian, et al.'
      },
      {
        title: 'Time',
        content: '17 February 2020'
      },
      {
        title: 'Link',
        url: 'http://gpb.big.ac.cn/news/1010',
        content: 'Genomics, Proteomics & Bioinformatics'
      }
    ]
  },
{
    year: '2020',
    month: '2.15',
    date: '2020年2月15日',
    updator: 'Pan Li',
    title: 'Congrats: SCALE method is selected as "Top Ten Algorithms and Tools for Bioinformatics in China" in 2019',
    img: require('../assets/news/newsAvatar/GPB_top10-2.png'),
    content: {
      text: 'Congrats: SCALE method is selected as "Top Ten Algorithms and Tools for Bioinformatics in China" in 2019',
      img: [require('../assets/news/newsDetails/GPB_Top10_2019-2.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'SCALE method for single-cell ATAC-seq analysis via latent feature extraction'
      },
      {
        title: 'Authors',
        content: 'L. Xiong, K. Xu, K. Tian, et al.'
      },
      {
        title: 'Time',
        content: '15 February 2020'
      },
      {
        title: 'Link',
        url: 'http://gpb.big.ac.cn/news/1008',
        content: 'Genomics, Proteomics & Bioinformatics'
      }
    ]
  },
  {
    year: '2020',
    month: '2.3',
    date: '2020年2月3日',
    updator: 'Pan Li',
    title: 'Publication: Our paper on Keth-seq for transcriptome-wide RNA structure was accepted by Nature chemical biology!',
    img: require('../assets/news/newsAvatar/ncb.png'),
    content: {
      text: 'Publication: Our paper on Keth-seq for transcriptome-wide RNA structure was accepted by Nature chemical biology!',
      img: [require('../assets/news/newsDetails/ncb-gongjing-2020.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'Keth-seq for transcriptome-wide RNA structure mapping'
      },
      {
        title: 'Authors',
        content: 'X. Weng, J. Gong, Y. Chen, T. Wu et al.'
      },
      {
        title: 'Time',
        content: '03 February 2020'
      },
      {
        title: 'Link',
        url: 'https://doi.org/10.1038/s41589-019-0459-3',
        content: 'Nature Chemical Biology'
      }
    ]
  },
  {
    year: '2019',
    month: '12.30',
    title: 'Congrats: Lei Xiong won the 2019 Advanced Innovation Center Structure Biology outstanding scholarship',
    date: '2019年12月30日',
    updator: 'Pan Li',
    content: {
      text: 'Lei Xiong won the 2019 Advanced Innovation Center Structure Biology outstanding scholarship',
      img: [require('../assets/news/newsDetails/2019_innovation.jpg')]
    },
    img: require('../assets/news/newsAvatar/innovation-500x500.png'),
    others: [
    
      {
        title: 'Project',
        content: 'SCALE method for single-cell ATAC-seq analysis via latent feature extraction'
      },
      {
        title: 'Direction',
        content: 'Single Cell'
      },
      {
        title: 'Amount',
        content: 'RMB 20,000'
      }
    ]
  },
  {
    year: '2019',
    month: '12.30',
    title: 'Congrats: Pan Li won the 2019 Advanced Innovation Center Structure Biology outstanding scholarship',
    date: '2019年12月30日',
    updator: 'Pan Li',
    content: {
      text: 'Pan Li won the 2019 Advanced Innovation Center Structure Biology outstanding scholarship',
      img: [require('../assets/news/newsDetails/2019_innovation.jpg')]
    },
    img: require('../assets/news/newsAvatar/innovation-500x500.png'),
    others: [
    
      {
        title: 'Project',
        content: 'Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity'
      },
      {
        title: 'Direction',
        content: 'RNA secondary structure'
      },
      {
        title: 'Amount',
        content: 'RMB 20,000'
      }
    ]
  },{
    year: '2019',
    month: '11.15',
    date: '2019年11月15日',
    updator: 'Pan Li',
    title: 'Seminar: We invited Dr. William J. Greenleaf from Stanford University to talk about a single cell framework for multi-omic analysis',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Greenleaf.png')]
    },
    img: require('../assets/news/newsAvatar/Greenleaf.jpg'),
    others: [
      {
        title: 'Title',
        content: 'A single cell framework for multi-omic analysis of disease identifies malignant regulatory signatures'
      },
      {
        title: 'Speaker',
        content: 'William J. Greenleaf, PhD'
      },
      {
        title: 'Time',
        content: '13:30-15:00 pm, Nov. 15th (Friday), 2019'
      },
      {
        title: 'Venue',
        content: 'ZhengYutong Lecture Hall(郑裕彤讲堂)'
      }
    ]
  },
  {
    year: '2019',
    month: '10.8',
    date: '2019年10月8日',
    updator: 'Pan Li',
    title: 'Publication: Our paper on single-cell ATAC-seq analysis was accepted by Nature Communications!',
    img: require('../assets/news/newsAvatar/nc.png'),
    content: {
      text: 'Publication: Our paper on single-cell ATAC-seq analysis via latent feature extraction was accepted by Nature Communications!',
      img: [require('../assets/news/newsDetails/NC_xionglei_2019.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'SCALE method for single-cell ATAC-seq analysis via latent feature extraction'
      },
      {
        title: 'Authors',
        content: 'L. Xiong, K. Xu, K. Tian, et al.'
      },
      {
        title: 'Time',
        content: '08 October 2019'
      },
      {
        title: 'Link',
        url: 'https://www.nature.com/articles/s41467-019-12630-7',
        content: 'Nature Communications'
      }
    ]
  },
  {
    year: '2019',
    month: '8.23',
    title: 'Congrats: Sun lei passed the PhD thesis defense',
    date: '2019年8月23日',
    updator: 'Pan Li',
    content: {
      text: 'Sun lei completed the doctoral graduation reply',
      img: [require('../assets/news/newsDetails/SunleiGraduate.png')]
    },
    img: require('../assets/news/newsAvatar/SunleiGraduate.png'),
    others: [
      {
        title: 'Event',
        content: 'Ph.D. student Sun lei completed his doctoral diploma and is about to receive his Ph.D.'
      },
      {
        title: 'Venue',
        content: 'Room 425, New Life Science Building, THU'
      }
    ]
  },
  {
    year: '2019',
    month: '8.23',
    date: '2019年8月23日',
    updator: 'Pan Li',
    title: 'Seminar: We invited Dr. Yaël Fregier from University of Artois to talk about Transfer learning for GANs',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Fregier.png')]
    },
    img: require('../assets/news/newsAvatar/Fregier.png'),
    others: [
      {
        title: 'Title',
        content: 'Transfer learning for GANs'
      },
      {
        title: 'Speaker',
        content: 'Yaël Fregier, PhD'
      },
      {
        title: 'Time',
        content: '16:00-17:00pm, Aug. 23th (Friday), 2019'
      },
      {
        title: 'Venue',
        content: 'Room 407, New Life Science Building, THU'
      }
    ]
  },
  {
    year: '2019',
    month: '8.12',
    date: '2019年8月12日',
    updator: 'Pan Li',
    title: 'Seminar: We invited Dr. Eric Miska from University of Cambridge to talk about RNA structure and chemical modification',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/ERIC_MISKA.png')]
    },
    img: require('../assets/news/newsAvatar/ERIC_MISKA.png'),
    others: [
      {
        title: 'Title',
        content: 'RNA:from structure and chemical modification to new functions'
      },
      {
        title: 'Speaker',
        content: 'Eric Miska, PhD'
      },
      {
        title: 'Time',
        content: '11:00-12:00pm, Aug. 12th (Monday), 2019'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Life Science Building, THU'
      }
    ]
  },
  {
    year: '2019',
    month: '8.5',
    date: '2019年8月5日',
    updator: 'Pan Li',
    title: 'Seminar: We invited Dr. Chuan He from HHMI to talk about RNA methylation and chromatin structure',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/He_Chuan.png')]
    },
    img: require('../assets/news/newsAvatar/He_Chuan.png'),
    others: [
      {
        title: 'Title',
        content: 'Studies of RNA methylation and chromatin structure'
      },
      {
        title: 'Speaker',
        content: 'Chuan He, PhD'
      },
      {
        title: 'Time',
        content: '14:30-16:00am, Aug. 5th (Monday), 2019'
      },
      {
        title: 'Venue',
        content: 'Room B323, Medical Science Building, THU'
      }
    ]
  },
  {
    year: '2019',
    month: '7.3',
    date: '2019年7月3日',
    updator: 'Pan Li',
    title: 'Seminar: We invited Dr. Zhang Chaolin from Columbia University to talk about RNA in neurodevelopment',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/ZhangChaolin.png')]
    },
    img: require('../assets/news/newsAvatar/ZhangChaolin.png'),
    others: [
      {
        title: 'Title',
        content: 'An RNA-centric view of neurodevelopment: From single nucleotide resolution maps to functions'
      },
      {
        title: 'Speaker',
        content: 'Chaolin Zhang, PhD'
      },
      {
        title: 'Time',
        content: '16:00-18:00, July 3rd, 2019'
      },
      {
        title: 'Venue',
        content: 'Room B323, Medical Building, THU'
      }
    ]
  },
  {
    year: '2019',
    month: '5.17',
    date: '2019年5月17日',
    updator: 'Pan Li',
    title: 'Seminar: We invited Dr. Yi Xing from University of Pennsylvania to talk about alternative isoform variation',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/2019_Xingyi.png')]
    },
    img: require('../assets/news/newsAvatar/Yi_Xing-1.png'),
    others: [
      {
        title: 'Title',
        content: 'Alternative isoform variation in cancer transcriptomes'
      },
      {
        title: 'Speaker',
        content: 'Xing yi, PhD'
      },
      {
        title: 'Time',
        content: '14:00-15:00, May 17th, 2019'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2019',
    month: '2.7',
    date: '2019年2月7日',
    updator: 'Pan Li',
    title: 'Publication: Our paper on RNA structures across cellular compartments was accepted by Nature Structure & molecular biology!',
    img: require('../assets/news/newsAvatar/nsmb-500x500.png'),
    content: {
      text: 'Publication: Our paper on RNA structures across cellular compartments was accepted by Nature Structure & molecular biology!',
      img: [require('../assets/news/newsDetails/nsmb-sunlei-2019.png')]
    },
    others: [
      {
        title: 'Title',
        content: 'RNA structure maps across mammalian cellular compartments'
      },
      {
        title: 'Authors',
        content: 'L. Sun, F. M. Fazal, P. Li et al.'
      },
      {
        title: 'Time',
        content: '07 February 2019'
      },
      {
        title: 'Link',
        url: 'https://www.nature.com/articles/s41594-019-0200-7',
        content: 'Nature Structure & Molecular Biology'
      }
    ]
  },
  {
    year: '2019',
    month: '2.2',
    date: '2019年2月2日',
    updator: 'Pan Li',
    title: 'PhD student Kui Xu attends the 33rd AAAI Conference on Artificial Intelligence (AAAI 2019) for spotlight presentation at Hawaii, USA',
    img: require('../assets/news/newsAvatar/xukui-20190127-1-1-500x500.jpg'),
    content: {
      text: 'PhD student Kui Xu attends the 33rd AAAI Conference on Artificial Intelligence (AAAI 2019) for spotlight presentation at Hawaii, USA',
      img: [require('../assets/news/newsAvatar/xukui-20190127-1-1-500x500.jpg')]
    },
    others: [
      {
        title: 'Date',
        content: 'Jan. 27th, 2019 – Feb. 2nd, 2019'
      },
      {
        title: 'Attendee',
        content: 'Kui Xu'
      },
      {
        title: 'Link',
        url: 'https://aaai.org/Conferences/AAAI-19/',
        content: 'AAAI-19'
      }
    ]
  },
  {
    year: '2018',
    month: '12.1',
    title: 'Congrats: Qian Xingyang won the 2018 Advanced Innovation Center Structure Biology innovation scholarship',
    date: '2018年12月1日',
    updator: 'Pan Li',
    content: {
      text: 'Qian Xingyang won the 2018 Advanced Innovation Center Structure Biology innovation scholarship',
      img: [require('../assets/news/newsDetails/2018_innovation.jpg')]
    },
    img: require('../assets/news/newsAvatar/innovation-500x500.png'),
    others: [
      {
        title: 'Project',
        content: 'Functional and structural analysis of conserved secondary structural elements of lncRNA'
      },
      {
        title: 'Direction',
        content: 'RNA secondary structure'
      },
      {
        title: 'Amount',
        content: 'RMB 30,000'
      }
    ]
  },
  {
    year: '2018',
    month: '11.30',
    title: 'Seminar: We invited Dr. Anindya Dutta from University of Virginia to talk about tRNA-Derived fragments and long noncoding RNAs',
    date: '2018年11月30日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Anindya_Dutta.png')]
    },
    img: require('../assets/news/newsAvatar/Anindya_Dutta-1-498x500.png'),
    others: [
      {
        title: 'Title',
        content: 'tRFs and long noncoding RNAs, with a short digression on HPV induced cell transformation'
      },
      {
        title: 'Speaker',
        content: 'Anindya Dutta MD, PhD'
      },
      {
        title: 'Time',
        content: '11:00-12:00am, Nov. 30th (Friday), 2018'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2018',
    month: '11.21',
    title: 'Publication: Our paper on ZIKV RNA structures was accepted by Cell Host & Microbe and online today!',
    date: '2018年11月21日',
    updator: 'Pan Li',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/CellHost-1.png')]
    },
    img: require('../assets/news/newsAvatar/CellHost-500x500.png'),
    others: [
      {
        title: 'Title',
        content: 'Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity'
      },
      {
        title: 'Authors',
        content: 'P. Li, Y. Wei, M. Mei, L. Tang et al.'
      },
      {
        title: 'Time',
        content: '21 November 2018'
      },
      {
        title: 'Link',
        url: 'https://www.sciencedirect.com/science/article/pii/S1931312818305493',
        content: 'Cell Host & Microbe'
      }
    ]
  },
  {
    year: '2018',
    month: '11.17',
    title: 'Publication: Our review on lncRNA structure and interaction was accepted by Trends in Biochemical Sciences and online today!',
    date: '2018年11月17日',
    updator: 'Pan Li',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/TrendBioScience.png')]
    },
    img: require('../assets/news/newsAvatar/TrensBioScience-497x500.png'),
    others: [
      {
        title: 'Title',
        content: 'Revealing lncRNA Structures and Interactions by Sequencing-Based Approaches'
      },
      {
        title: 'Authors',
        content: 'X. Qian, J. Zhao, P.Y. Yeung et al.'
      },
      {
        title: 'Time',
        content: '17 November 2018'
      },
      {
        title: 'Link',
        url: 'https://www.sciencedirect.com/science/article/pii/S096800041830197X?via%3Dihub',
        content: 'Trends in Biochemical Sciences'
      }
    ]
  },
  {
    year: '2018',
    month: '11.8',
    title: 'Seminar: We invited Dr. Xingqi Chen from Karolinska Institute to talk about ATAC-seq',
    date: '2018年11月8日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Xingqi_Chen.png')]
    },
    img: require('../assets/news/newsAvatar/Xingqi_Chen-1-500x500.png'),
    others: [
      {
        title: 'Title',
        content: 'Joint single-cell DNA accessibility and protein eptiope profiling reveals environmental regulation of epigenomic heterogeneit'
      },
      {
        title: 'Speaker',
        content: 'Xingqi Chen, PhD'
      },
      {
        title: 'Time',
        content: '2:00-3:00 pm, Nov. 8th (Thursday), 2018'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2018',
    month: '11.1',
    title: 'Publication: Our paper on molecular structure estimation from Cryo-EM density volumes was accepted by The 33rd AAAI Conference on Artificial Intelligence (AAAI)!',
    date: '2018年11月8日',
    updator: 'Pan Li',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/kui_AAAI.png')]
    },
    img: require('../assets/news/newsAvatar/AAAI-500x500.png'),
    others: [
      {
        title: 'Title',
        content: 'A²-Net: Molecular Structure Estimation From Cryo-EM Density Volumes'
      },
      {
        title: 'Authors',
        content: 'Kui Xu et al.'
      },
      {
        title: 'Time',
        content: '1 November 2018'
      },
      {
        title: 'Link',
        url: 'https://aaai.org/Conferences/AAAI-19/',
        content: 'AAAI-19'
      }
    ]
  },
  {
    year: '2018',
    month: '10.23',
    title: 'Seminar: We invited Dr. Shuo Gu from NIH to talk about miRNA processing',
    date: '2018年10月23日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/gushuo-1.jpg')]
    },
    img: require('../assets/news/newsAvatar/Gu_Shuo.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Structural differences between pri-miRNA paralogs promotes alternative Drosha cleavage and expands target repertories'
      },
      {
        title: 'Speaker',
        content: 'Shuo Gu, PhD'
      },
      {
        title: 'Time',
        content: '1:30-3:00 pm, Oct. 23th (Tuesday), 2018'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2018',
    month: '8.22',
    title: 'Seminar: We invited Dr. Ye Grace Chen from Yale University to talk about circular RNA in immunity',
    date: '2018年8月22日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Chenye.jpg')]
    },
    img: require('../assets/news/newsAvatar/Ye_Grace_Chen.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Circular RNA immunity'
      },
      {
        title: 'Speaker',
        content: 'Ye Grace Chen, PhD'
      },
      {
        title: 'Time',
        content: '10:00-11:00 am, Aug. 22nd (Wednesday), 2018'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2018',
    month: '8.7',
    title: 'Our RNA-RNA interactions review paper was accepted by Quantitative Biology and online today !',
    date: '2018年8月7日',
    updator: 'Pan Li',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/QB-review.png')]
    },
    img: require('../assets/news/newsAvatar/QB_logo-500x500.png'),
    others: [
      {
        title: 'Title',
        content: 'Advances and challenges towards the study of RNA-RNA interactions in a transcriptome-wide scale'
      },
      {
        title: 'Authors',
        content: 'J. Gong, Y. Ju et al.'
      },
      {
        title: 'Time',
        content: '07 August 2018'
      },
      {
        title: 'Link',
        url: 'https://link.springer.com/article/10.1007/s40484-018-0146-5',
        content: 'Quantitative Biology'
      }
    ]
  },
  {
    year: '2018',
    month: '7.17',
    title: 'Seminar: We invited Dr. Xiang-Jun Lu from Columbia University to talk about illuminating the 3D structure of RNA with 3DNA-DSSR',
    date: '2018年7月17日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      // img: [require('../assets/news/newsAvatar/Xiang_Jun_Lu-502x500.jpg')]
      img: []
    },
    img: require('../assets/news/newsAvatar/Xiang_Jun_Lu-502x500.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Illuminating the 3D structure of RNA with 3DNA-DSSR'
      },
      {
        title: 'Speaker',
        content: 'Xiang-Jun Lu'
      },
      {
        title: 'Time',
        content: '2:00-3:00 pm, June. 27th (Wednesday), 2018'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2018',
    month: '7.6',
    title: 'Seminar: We invited Dr. Yinghao Wu from Albert Einstein College to talk about membrane receptors',
    date: '2018年7月6日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      // img: [require('../assets/news/newsAvatar/Yinghao_Wu-500x500.jpg')]
      img: []
    },
    img: require('../assets/news/newsAvatar/Yinghao_Wu-500x500.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Understand binding kinetics of membrane receptors and their functional roles in cell adhesion and signaling by multi-scale modeling'
      },
      {
        title: 'Speaker',
        content: 'Yinghao Wu, PhD'
      },
      {
        title: 'Time',
        content: '2:00-3:00pm, July. 6th (Friday), 2018'
      },
      {
        title: 'Venue',
        content: 'Room B416, Medical Science Building, THU'
      }
    ]
  },
  {
    year: '2018',
    month: '1.8',
    title: 'Seminar: We invited Dr. Yanlei Diao from University of Massachusetts Amherst to talk about big and fast data analysis for genomics',
    date: '2018年1月8日',
    updator: 'xukui',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Yanlei_Diao.png')]
    },
    img: require('../assets/news/newsAvatar/Yanlei_Diao-500x500.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Big and fast data analysis for genomics'
      },
      {
        title: 'Speaker',
        content: 'Yanlei Diao'
      },
      {
        title: 'Time',
        content: '10:00-11:00 am, Jan. 8th (Monday), 2018'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2017',
    month: '12.21',
    title: 'Congrats: Xu Kui won the 2017 Advanced Innovation Center Structure Biology innovation scholarship',
    date: '2017年12月21日',
    updator: 'Pan Li',
    content: {
      text: 'Xu Kui won the 2017 Advanced Innovation Center Structure Biology innovation scholarship',
      img: [require('../assets/news/newsDetails/2017_innovation.jpg')]
    },
    img: require('../assets/news/newsAvatar/innovation-red-500x500.png'),
    others: [
      {
        title: 'Project',
        content: 'Application of virtual reality technology in structural biology'
      },
      {
        title: 'Direction',
        content: 'Computational structural biology, graphic images, machine learning'
      },
      {
        title: 'Amount',
        content: 'RMB 30,000'
      }
    ]
  },
  {
    year: '2017',
    month: '12.21',
    title: 'Congrats: Piao Meiling won the 2017 Advanced Innovation Center Structure Biology innovation scholarship',
    date: '2017年12月21日',
    updator: 'Pan Li',
    content: {
      text: 'XPiao Meiling won the 2017 Advanced Innovation Center Structure Biology innovation scholarship',
      img: [require('../assets/news/newsDetails/2017_innovation2.jpg')]
    },
    img: require('../assets/news/newsAvatar/innovation-red-500x500.png'),
    others: [
      {
        title: 'Project',
        content: 'Probing RNA structurome in mouse early embryos by smartSHAPE, a new low-input RNA secondary structure probing technique'
      },
      {
        title: 'Direction',
        content: 'RNA secondary structure'
      },
      {
        title: 'Amount',
        content: 'RMB 30,000'
      }
    ]
  },
  {
    year: '2017',
    month: '11.6',
    title: 'Seminar: We invited Dr. Mark A. Kay from Stanford University to talk about a functional tRNA',
    date: '2017年11月6日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Mark_Kay_2017.png')]
    },
    img: require('../assets/news/newsAvatar/Mark_Kay.jpg'),
    others: [
      {
        title: 'Title',
        content: 'A tRNA derived small RNA regulates ribosome biogenesis and is a target for cancer therapy'
      },
      {
        title: 'Speaker',
        content: 'Mark A. Kay MD, PhD'
      },
      {
        title: 'Time',
        content: '10:00-11:00 am, Nov. 6th (Monday), 2017'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2017',
    month: '10.18',
    title: 'Seminar: We invited Dr. Huang Huang from Stanford University to talk about single cell analysis of antigen-specific T cell response',
    date: '2017年10月18日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Huang_Huang.png')]
    },
    img: require('../assets/news/newsAvatar/Huang_Huang-500x500.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Single cell analysis of antigen-specific T cell response against Mycobacterium tuberculosis'
      },
      {
        title: 'Speaker',
        content: 'Huang Huang, PhD'
      },
      {
        title: 'Time',
        content: '10:00-11:00 am, Oct. 18th (Wednesday), 2017'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2017',
    month: '10.12',
    title: 'Publication: Our review on RNA structure probing was accepted by GPB and online today!',
    date: '2017年10月12日',
    updator: 'Pan Li',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/GPB.png')]
    },
    img: require('../assets/news/newsAvatar/GPB-1-500x500.png'),
    others: [
      {
        title: 'Title',
        content: 'NA Regulations and Functions Decoded by Transcriptome-wide RNA Structure Probing'
      },
      {
        title: 'Authors',
        content: 'M. Piao et al.'
      },
      {
        title: 'Time',
        content: '12 October 2017'
      },
      {
        title: 'Link',
        url: 'https://www.sciencedirect.com/science/article/pii/S1672022917301389?via%3Dihub',
        content: 'Genomics Proteomics Bioinformatics'
      }
    ]
  },
  {
    year: '2017',
    month: '10.4',
    title: 'Publication: Our paper on RNA-RNA interaction database RISE was accepted by NAR and online today!',
    date: '2017年10月4日',
    updator: 'Pan Li',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/NAR-1.png')]
    },
    img: require('../assets/news/newsAvatar/NAR-487x500.png'),
    others: [
      {
        title: 'Title',
        content: 'RISE: a database of RNA interactome from sequencing experiments'
      },
      {
        title: 'Authors',
        content: 'J. Gong, D. Shao et al.'
      },
      {
        title: 'Time',
        content: '4 October 2017'
      },
      {
        title: 'Link',
        url: 'https://academic.oup.com/nar/article/46/D1/D194/4344571',
        content: 'Nucleic Acids Research'
      }
    ]
  },
  {
    year: '2016',
    month: '10.19',
    title: 'Seminar: We invited Dr. Huafeng Xu from UCSF to talk about protein evolution',
    date: '2017年10月19日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Huafeng_Xu.png')]
    },
    img: require('../assets/news/newsAvatar/Huafeng_Xu.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Protein evolution under a computational microscope'
      },
      {
        title: 'Speaker',
        content: 'Huafeng Xu, PhD'
      },
      {
        title: 'Time',
        content: '2:00-3:00 pm, Oct. 19th (Wednesday), 2016'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2016',
    month: '8.13',
    title: 'Seminar: We invited Dr. Michael Snyder from Stanford University to talk about autism spectrum disorders',
    date: '2016年8月13日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Michael_Snyder.jpeg')]
    },
    img: require('../assets/news/newsAvatar/Michael_Snyder.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Building integrative frameworks to study autism spectrum disorders'
      },
      {
        title: 'Speaker',
        content: 'Michael Snyder, PhD'
      },
      {
        title: 'Time',
        content: '10:30-11:30 pm, Aug. 8th (Thursday), 2016'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2016',
    month: '5.31',
    title: 'Seminar: We invited Dr. Ido Golding from Baylor College of Medicine to talk about kinetics of gene regulation',
    date: '2016年5月31日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Ido_Golding.png')]
    },
    img: require('../assets/news/newsAvatar/Ido_Golding-500x500.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Deciphering the stochastic kinetics of gene regulation'
      },
      {
        title: 'Speaker',
        content: 'Ido Golding, PhD'
      },
      {
        title: 'Time',
        content: '10:00-11:30 am, May. 31st (Tuesday), 2016'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2016',
    month: '5.9',
    title: 'Seminar: We invited Dr. Luonan Chen from Shanghai Institutes for Biological Sciences to talk about early-signals for critical transition',
    date: '2016年5月9日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Luonan_Chen.png')]
    },
    img: require('../assets/news/newsAvatar/Luonan_Chen-500x500.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Detecting early-signals for critical transition during complex biological processes by dynamical network biomarkers'
      },
      {
        title: 'Speaker',
        content: 'Luonan Chen, PhD'
      },
      {
        title: 'Time',
        content: '10:00-11:30 am, May. 9th (Monday), 2016'
      },
      {
        title: 'Venue',
        content: 'Room B321, Medical Science Building, THU'
      }
    ]
  },
  {
    year: '2016',
    month: '3.24',
    title: 'Seminar: We invited Dr. Yi Xing from University of Pennsylvania to talk about transcriptome isoform complexity',
    date: '2016年3月24日',
    updator: 'Qian, Xingyang',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Yi_Xing.png')]
    },
    img: require('../assets/news/newsAvatar/Yi_Xing-1.png'),
    others: [
      {
        title: 'Title',
        content: 'Elucidate transcriptome isoform complexity using massive RNA-seq data'
      },
      {
        title: 'Speaker',
        content: 'Yi Xing, PhD'
      },
      {
        title: 'Time',
        content: '10:00-11:00 am, Mar. 24th (Thursday), 2016'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2015',
    month: '12.25',
    title: 'Seminar: We invited Dr. Wei Wang from UCSD to talk about epigenomics in development',
    date: '2015年12月25日',
    updator: 'xukui',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Wei_Wang.png')]
    },
    img: require('../assets/news/newsAvatar/Wei_Wang-1.png'),
    others: [
      {
        title: 'Title',
        content: 'Epigenomics in development'
      },
      {
        title: 'Speaker',
        content: 'Wei Wang, PhD'
      },
      {
        title: 'Time',
        content: '2:00-3:00 pm, Dec. 25th (Friday), 2015'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2015',
    month: '11.16',
    title: 'Seminar: We invited Dr. Mark A. Kay from Stanford University to talk about novel nucleic acid vector approaches',
    date: '2015年11月16日',
    updator: 'xukui',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Mark_Kay_2015.png')]
    },
    img: require('../assets/news/newsAvatar/Mark_Kay.jpg'),
    others: [
      {
        title: 'Title',
        content: 'Novel nucleic acid vector approaches for delivering genes and non-coding RNAs'
      },
      {
        title: 'Speaker',
        content: 'Mark A. Kay MD, PhD'
      },
      {
        title: 'Time',
        content: '9:30-10:30am, Nov. 16th (Monday), 2015'
      },
      {
        title: 'Venue',
        content: 'Room 143, New Biology Building, THU'
      }
    ]
  },
  {
    year: '2015',
    month: '7.15',
    title: 'Seminar: We invited Dr. Yaoqi Zhou from Institute for Glycomics to talk about personalized medicine',
    date: '2015年7月15日',
    updator: 'xukui',
    content: {
      text: '',
      img: [require('../assets/news/newsDetails/Yaoqi_Zhou.png')]
    },
    img: require('../assets/news/newsAvatar/Yaoqi_Zhou-1.png'),
    others: [
      {
        title: 'Title',
        content: '迈向个性化医疗：生物信息学和分子生物学的结合'
      },
      {
        title: 'Speaker',
        content: '周耀旗 博士'
      },
      {
        title: 'Time',
        content: '10:30-11:30am, July. 15th (Wednesday), 2015'
      },
      {
        title: 'Venue',
        content: 'Room B323, Medical Science Building, THU'
      }
    ]
  },
  {
    year: '2015',
    month: '5.23',
    title: 'Welcome: Sun Lei joined the lab!',
    date: '2015年5月23日',
    updator: 'Pan Li',
    content: {
      text: '孙磊加入实验室',
      img: [require('../assets/news/newsAvatar/sunlei-500x500.jpg')]
    },
    img: require('../assets/news/newsAvatar/sunlei-500x500.jpg'),
    others: [
      {
        title: 'Time',
        content: 'May 23rd, 2015'
      },
      {
        title: 'Position',
        content: 'PhD Student'
      },
      {
        title: 'Field',
        content: 'RNA Structure probing and functional analysis'
      }
    ]
  },
  {
    year: '2015',
    month: '3.31',
    title: 'Welcome: Xu Kui joined the lab!',
    date: '2015年3月31日',
    updator: 'Pan Li',
    content: {
      text: '徐魁加入实验室',
      img: [require('../assets/news/newsAvatar/xukui2.jpg')]
    },
    img: require('../assets/news/newsAvatar/xukui2.jpg'),
    others: [
      {
        title: 'Time',
        content: 'Mar. 31st, 2015'
      },
      {
        title: 'Position',
        content: 'PhD Student'
      },
      {
        title: 'Field',
        content: 'Machine learning, 3D image modeling'
      }
    ]
  },
  {
    year: '2015',
    month: '2.28',
    title: 'Welcome: Wei Wendi joined the lab!',
    date: '2015年2月28日',
    updator: 'Pan Li',
    content: {
      text: '魏文迪加入实验室',
      img: [require('../assets/news/newsAvatar/weiwendi-500x500.jpg')]
    },
    img: require('../assets/news/newsAvatar/weiwendi-500x500.jpg'),
    others: [
      {
        title: 'Time',
        content: 'Feb. 28th, 2015'
      },
      {
        title: 'Position',
        content: 'Lab manager'
      },
      {
        title: 'Field',
        content: 'Financial reimbursement, staff reception, …'
      }
    ]
  },
]

export default news
